import React from "react";
import { Alert } from "antd";
import { Select } from "antd";

const { Option } = Select;
const RolesForm = (props) => {
  const {
    handleInputChange,
    isAuthor,
    isVideoCreator,
    clearanceLevel,
    formError,
  } = props;

  return (
    <div>
      {formError ? (
        <div style={{ marginBottom: 25 }}>
          <Alert type="error" message={formError} />
        </div>
      ) : null}

      <div style={{ marginTop: 25 }}>
        <label>Author</label>
        <Select 
          style={{ width: '100%' }}
          defaultValue={isAuthor} 
          onChange={(e) => handleInputChange(e, "isAuthor")}
        >
          <Option value={true}>True</Option>
          <Option value={false}>False</Option>
        </Select>
      </div>

      <div style={{ marginTop: 25 }}>
        <label>Video Creator</label>
        <Select 
          style={{ width: '100%' }}
          defaultValue={isVideoCreator} 
          onChange={(e) => handleInputChange(e, "isVideoCreator")}
        >
          <Option value={true}>True</Option>
          <Option value={false}>False</Option>
        </Select>
      </div>

      <div style={{ marginTop: 25 }}>
        <label>ClearanceLevel</label>
        <Select 
          style={{ width: '100%' }}
          defaultValue={clearanceLevel} 
          onChange={(e) => handleInputChange(e, "clearanceLevel")}
        >
          <Option value="normal">Normal</Option>
          <Option value="admin">Admin</Option>
        </Select>
      </div>
    </div>
  );
};

export default RolesForm;
