import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Modal, Row, Table, Input } from "antd";
import CreateUpdateForm from "./CreateUpdateForm";
import DeleteTrack from "./DeleteTrack";
import {
  createTrack,
  fetchTracks,
  fetchModules,
  updateTrack,
  deleteTrack,
  searchTrack,
} from "../../store/actions/trackActions";
import { getVideos as fetchVideos } from "../../store/actions/videoActions";
import { connect } from "react-redux";
import { fetchBadges } from "../../store/actions/badgesActions";
import {
  getBase64,
  uploadOtherFilesToFirebase,
} from "../../utils/uploadFileToFirebase";

const { Search } = Input;

const Tracks = ({
  fetchTracks,
  modules,
  tracks,
  createTrack,
  updateTrack,
  deleteTrack,
  fetchModules,
  fetchBadges,
  badges,
  tracksError,
  fetchVideos,
  videos,
  searchResult,
  searchTrack,
}) => {
  const [formError, setFormError] = useState("");
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("createTrack");
  const [name, setName] = useState("");
  const [preSalesWhatsAppScript, setPreSalesWhatsAppScript] = useState("");
  const [preSalesCopy, setPreSalesCopy] = useState("");
  const [description, setDescription] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [trackId, setTrackId] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailS, setThumbnailS] = useState(null);
  const [thumbnailURL, setThumbnailURL] = useState(null);
  const [thumbnailSURL, setThumbnailSURL] = useState(null);
  const [moduleIds, setModuleIds] = useState([]);
  const [isFree, setIsFree] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);
  const [badgeIds, setBadgeIds] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedResults, setSearchedResults] = useState([]);
  const [introVideoId, setIntroVideoId] = useState("");
  const [publisher, setPublisher] = useState("");
  const [date, setDate] = useState("");
  const [introVidState, setIntroVidState] = useState({
    videoURL: null,
    videoUploadProgress: 0,
    isVideoInProgress: false,
    videoUploadError: null,
    videoUpload: null,
  });

  useEffect(() => {
    fetchTracks();
    fetchModules();
    fetchBadges();
    fetchVideos();
  }, [fetchTracks, fetchModules, fetchBadges, fetchVideos]);

  useEffect(() => {
    if (searchResult && searchText) {
      setSearchedResults(searchResult.video_tracks || []);
    }

    if (!searchText || searchText === "") {
      setSearchedResults([]);
    }
  }, [searchResult, searchText]);

  const openModal = (modalType, record) => {
    setIsModalVisible(true);
    setType(modalType);
    if (modalType === "deleteTrack") {
      setTrackId(record.id);
    }
    if (modalType === "editTrack") {
      setTrackId(record.id);
      setName(record.name ? record.name : "");
      setThumbnailURL(record.thumbnail ? record.thumbnail : "");
      setThumbnailSURL(
        record.portraitThumbnail ? record.portraitThumbnail : ""
      );
      setDescription(record.description ? record.description : "");
      setIsFeatured(String(record.isFeatured));
      setIsFree(String(record.isFree));
      setIsDraft(String(record.draft));
      setIntroVideoId(record ? record.introVideo.id : "");
      setPublisher(record ? record.producer : "");
      setDate(record.launchDay);
      setIntroVidState({
        ...introVidState,
        videoURL: record?.introVideo?.mp4Url,
      });
      setModuleIds(record.modules.map((mod) => mod.id));
      setBadgeIds(record.badges.map((badge) => badge.id));
      setPreSalesCopy(
        record.preSales.preSalesCopy ? record.preSales.preSalesCopy : ""
      );
      setPreSalesWhatsAppScript(
        record.preSales.preSalesWhatsAppScript
          ? record.preSales.preSalesWhatsAppScript
          : ""
      );
    }
  };

  const clearFormData = () => {
    setName("");
    setDescription("");
    setIsFeatured("false");
    setIsFree("false");
    setPublisher("");
    setDate("");
    setBadgeIds([]);
    setModuleIds([]);
    setThumbnailURL("");
    setThumbnail(null);
    setThumbnailSURL("");
    setThumbnailS(null);
    setIntroVidState({
      videoURL: null,
      videoProgress: 0,
      isVideoInProgress: false,
      videoUploadError: null,
      videoUpload: null,
    });
  };

  const closeModal = () => {
    setIsModalVisible(false);
    clearFormData();
  };

  const handleOk = async () => {
    setLoading(true);

    if (type === "createTrack" || type === "editTrack") {
      if (!name) {
        setFormError("name of video track is required");
        setLoading(false);
        return false;
      }
    }

    if (type === "createTrack") {
      let fileURL = "";
      let fileSURL = "";

      if (thumbnail) {
        fileURL = await uploadOtherFilesToFirebase(thumbnail.file, "thumbnail");
      }

      if (thumbnailS) {
        fileSURL = await uploadOtherFilesToFirebase(
          thumbnailS.file,
          "portraitThumbnail"
        );
      }

      const finalPayload = {
        name,
        description,
        producer: publisher,
        launchDay: date,
        introVideo: introVideoId,
        isFeatured: isFeatured !== "false" ? true : false,
        isFree: isFree !== "false" ? true : false,
        draft: isDraft !== "false" ? true : false,
        modules: moduleIds,
        badges: typeof badgeIds === "object" ? badgeIds[0] : badgeIds,
        thumbnail: fileURL,
        portraitThumbnail: fileSURL,
        preSales: { preSalesCopy, preSalesWhatsAppScript },
      };
      await createTrack(finalPayload);
    }

    if (type === "editTrack") {
      let fileURL = "";
      let fileSURL = "";

      if (thumbnail) {
        fileURL = await uploadOtherFilesToFirebase(thumbnail.file, "thumbnail");
      } else {
        fileURL = thumbnailURL;
      }

      if (thumbnailS) {
        fileSURL = await uploadOtherFilesToFirebase(
          thumbnailS.file,
          "portraitThumbnail"
        );
      } else {
        fileSURL = thumbnailSURL;
      }

      const finalPayload = {
        name,
        description,
        producer: publisher,
        launchDay: date,
        introVideo: introVideoId,
        isFeatured: isFeatured !== "false" ? true : false,
        isFree: isFree !== "false" ? true : false,
        draft: isDraft !== "false" ? true : false,
        modules: moduleIds,
        badges: typeof badgeIds === "object" ? badgeIds[0] : badgeIds,
        thumbnail: fileURL,
        portraitThumbnail: fileSURL,
        preSales: { preSalesCopy, preSalesWhatsAppScript },
      };
      await updateTrack(finalPayload, trackId);
    }

    if (type === "deleteTrack") {
      await deleteTrack(trackId);
    }

    setLoading(false);
    clearFormData();
    closeModal();
    await fetchTracks();
  };

  const handleUploadChange = (fileUpload) => {
    setIntroVidState({
      ...introVidState,
      videoUpload: fileUpload,
    });
  };

  const handleInputChange = (event, eventType) => {
    if (eventType === "name") {
      setName(event.target.value);
    }

    if (eventType === "publisher") {
      setPublisher(event.target.value);
    }

    if (eventType === "date") {
      setDate(event.toDate());
    }

    if (eventType === "description") {
      setDescription(event.target.value);
    }

    if (eventType === "preSalesWhatsAppScript") {
      setPreSalesWhatsAppScript(event.target.value);
    }

    if (eventType === "preSalesCopy") {
      setPreSalesCopy(event.target.value);
    }
  };

  const handleSelectChanger = (value, selectType) => {
    if (selectType === "isFeatured") {
      setIsFeatured(value);
    }

    if (selectType === "isModules") {
      setModuleIds(value);
    }

    if (selectType === "isFree") {
      setIsFree(value);
    }

    if (selectType === "isDraft") {
      setIsDraft(value);
    }

    if (selectType === "isBadges") {
      setBadgeIds(value);
    }

    if (selectType === "introVideo") {
      setIntroVideoId(value);
    }
  };

  const handleUpload = (upload) => {
    getBase64(upload.file.originFileObj, (imageUrl) => {
      setThumbnail(upload);
      setThumbnailURL(imageUrl);
    });
  };

  const handlePortraitUpload = (upload) => {
    getBase64(upload.file.originFileObj, (imageUrl) => {
      setThumbnailS(upload);
      setThumbnailSURL(imageUrl);
    });
  };

  const onSearch = async (value) => {
    if (value) {
      await searchTrack(value);
    }
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Badges",
      dataIndex: "Badges",
      key: "badges",
      render: (_, record) => (
        <div>
          {record.badges && record.badges.length > 0
            ? record.badges.map((badge) => {
                return <Button style={{ marginRight: 5 }}>{badge.name}</Button>;
              })
            : null}
        </div>
      ),
    },
    {
      title: "Free",
      dataIndex: "isFree",
      key: "isFree",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => {
        const aValue = a.isFree ? 1 : 0;
        const bValue = b.isFree ? 1 : 0;
        return aValue - bValue;
      },
      render: (_, record) => <div>{record.isFree ? "True" : "False"}</div>,
    },
    {
      title: "Draft",
      dataIndex: "isDraft",
      key: "isDraft",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => {
        const aValue = a.isDraft ? 1 : 0;
        const bValue = b.isDraft ? 1 : 0;
        return aValue - bValue;
      },
      render: (_, record) => <div>{record.draft ? "True" : "False"}</div>,
    },
    {
      title: "Featured",
      dataIndex: "isFeatured",
      key: "isFeatured",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => {
        const aValue = a.isFeatured ? 1 : 0;
        const bValue = b.isFeatured ? 1 : 0;
        return aValue - bValue;
      },
      render: (_, record) => <div>{record.isFeatured ? "True" : "False"}</div>,
    },
    {
      title: "CreatedAt",
      dataIndex: "createdAt",
      key: "createdAt",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <div>
          <Row gutter={4}>
            <Col>
              <Button
                type="primary"
                onClick={() => openModal("editTrack", record)}
              >
                Edit
              </Button>
            </Col>

            <Col>
              <Button danger onClick={() => openModal("deleteTrack", record)}>
                Delete
              </Button>
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div>
        <Row gutter={4}>
          <Col>
            <Button type="primary" onClick={() => openModal("createTrack")}>
              Create Track
            </Button>
          </Col>

          <Col>
            <Search
              value={searchText}
              placeholder="Search here.."
              onSearch={onSearch}
              style={{ width: 200 }}
              onChange={handleSearchChange}
            />
          </Col>
        </Row>
      </div>

      {formError ? (
        <div style={{ marginTop: 20 }}>
          {" "}
          <Alert message={formError} type="error" />{" "}
        </div>
      ) : (
        ""
      )}

      <div style={{ marginTop: 20 }}>
        <Table
          columns={columns}
          dataSource={
            searchText && searchedResults.length > 0 ? searchedResults : tracks
          }
        />
      </div>

      <Modal
        title={
          type === "createTrack"
            ? "Create Track"
            : type === "editTrack"
            ? "Edit Track"
            : type === "deleteTrack"
            ? "Delete Track"
            : "Insert something here"
        }
        visible={isModalVisible}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            danger={type === "deleteTrack"}
            loading={loading}
            onClick={handleOk}
          >
            {type === "createTrack"
              ? "Create"
              : type === "editTrack"
              ? "Update"
              : type === "deleteTrack"
              ? "Delete"
              : "Done"}
          </Button>,
        ]}
      >
        {type === "createTrack" || type === "editTrack" ? (
          <CreateUpdateForm
            name={name}
            publisher={publisher}
            date={date}
            description={description}
            handleInputChange={handleInputChange}
            handleSelectChanger={handleSelectChanger}
            isFeatured={isFeatured}
            moduleIds={moduleIds}
            modules={modules}
            badgeIds={badgeIds}
            formError={formError}
            trackError={tracksError}
            badges={badges}
            isFree={isFree}
            isDraft={isDraft}
            introVideoId={introVideoId}
            introVidState={introVidState}
            handleUploadChange={handleUploadChange}
            videos={videos}
            thumbnailURL={thumbnailURL}
            thumbnail={thumbnail}
            thumbnailSURL={thumbnailSURL}
            thumbnailS={thumbnailS}
            handleUpload={handleUpload}
            handlePortraitUpload={handlePortraitUpload}
            preSalesWhatsAppScript={preSalesWhatsAppScript}
            preSalesCopy={preSalesCopy}
          />
        ) : type === "deleteTrack" ? (
          <DeleteTrack name={name} />
        ) : (
          <div>No form type selected</div>
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  tracks: state.tracks.tracks,
  tracksError: state.tracks.trackError,
  modules: state.tracks.modules,
  badges: state.badges.badges,
  videos: state.videos.videos,
  searchResult: state.tracks.searchResult,
  searchError: state.tracks.searchError,
});

export default connect(mapStateToProps, {
  fetchTracks,
  createTrack,
  fetchModules,
  fetchBadges,
  updateTrack,
  deleteTrack,
  fetchVideos,
  searchTrack,
})(Tracks);
